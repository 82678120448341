import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getDocs, collection, query, orderBy, where } from 'firebase/firestore';
import { signOut, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LogoutIcon from '@mui/icons-material/Logout';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import NotesIcon from '@mui/icons-material/Notes';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentItem from './CommentItem';
import CommentForm from './CommentForm';
import {
  auth,
  fireStore,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
} from '../utils/FirebaseConfig';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const CommentBoxStyle = styled.div`
  width: 100%;
  #stack {
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 2.4rem;
  }
  .likes_and_comments_count {
    display: flex;
    margin-left: auto;
    flex-grow: 0.3;
    font-size: 1.8rem;
    line-height: 1.3em;
  }
  .icon_button {
    margin-left: auto;
  }
  .all_comments {
    border: solid 2px var(--gray-1);
    border-radius: 8px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    overflow: auto;
    max-height: 500px;
  }
  .button-type-button {
    background-color: var(--gray-1);
    color: var(--black);
    font-size: 2rem;
    display: inline-block;
    outline: none;
    border: none;
    padding: 0.5rem 3rem;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export default function CommentBox() {
  const { slug } = useParams();
  const [isAuth, setIsAuth] = useState(false);
  const [reloadTS, setReloadTS] = useState(Date.now());
  const [commentsList, setCommentsList] = useState([]);
  const [likesCount, setLikesCount] = useState(0);

  const commentsCollectionRef = useRef(collection(fireStore, 'comments'));
  const likesCollectionRef = useRef(collection(fireStore, 'likes'));

  const signin = (authProvider) => {
    signInWithPopup(auth, authProvider)
      .then((result) => {
        setIsAuth(true);
      })
      .catch(console.error);
  };

  const signout = () => {
    signOut(auth).then(() => {
      setIsAuth(false);
    });
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setIsAuth(true);
      // ...
    } else {
      // User is signed out
      setIsAuth(false);
    }
  });

  useEffect(() => {
    const qry = query(
      commentsCollectionRef.current,
      where('slug', '==', slug),
      orderBy('timestamp', 'desc')
    );

    const getComments = async () => {
      const data = await getDocs(qry);
      if (!data || !data.docs) return;
      console.log(`loaded comments: ${data.docs}`);
      setCommentsList(
        data.docs.map((document) => ({ ...document.data(), id: document.id }))
      );
    };

    getComments();
  }, [slug, reloadTS]);

  useEffect(() => {
    const qry = query(likesCollectionRef.current, where('slug', '==', slug));

    const getLikes = async () => {
      const data = await getDocs(qry);
      if (!data || !data.docs) return;
      console.log(`loaded likes: ${data.docs}`);
      setLikesCount(data.docs.length);
    };

    getLikes();
  }, [slug]);

  return (
    <CommentBoxStyle>
      <div className="container">
        <div id="stack">
          <div>
            <h1 className="title">Share your feedback</h1>
          </div>
          <div className="likes_and_comments_count">
            <h3>{likesCount}</h3>&nbsp;
            <FavoriteIcon fontSize="large" />
            &nbsp;&nbsp;
            <h3>{commentsList.length}</h3>&nbsp;
            <NotesIcon fontSize="large" />
          </div>
          {isAuth ? (
            <div className="icon_button">
              <Tooltip title="Sign out">
                <IconButton
                  onClick={signout}
                  aria-label="logout"
                  color="primary"
                >
                  <LogoutIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className="icon_button">
              <Tooltip title="Sign in with Google">
                <IconButton
                  onClick={() => signin(googleAuthProvider)}
                  aria-label="google"
                  color="primary"
                >
                  <GoogleIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sign in with Facebook">
                <IconButton
                  onClick={() => signin(facebookAuthProvider)}
                  aria-label="facebook"
                  color="primary"
                >
                  <FacebookIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sign in with Twitter">
                <IconButton
                  onClick={() => signin(twitterAuthProvider)}
                  aria-label="twitter"
                  color="primary"
                >
                  <TwitterIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div>
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >
            <CommentForm
              slug={slug}
              isAuth={isAuth}
              setReloadTS={setReloadTS}
              setLikesCount={setLikesCount}
            />
          </GoogleReCaptchaProvider>
        </div>
        {commentsList && commentsList.length > 0 ? (
          <div className="all_comments">
            {commentsList &&
              commentsList.map((item, index) => (
                <CommentItem
                  key={item.id}
                  index={index}
                  comment={item.comment}
                  uname={item.author.name}
                  timestamp={item.timestamp}
                  uid={item.author.id}
                  docId={item.id}
                  isAuth={isAuth}
                  setReloadTS={setReloadTS}
                />
              ))}
          </div>
        ) : (
          <div />
        )}
      </div>
    </CommentBoxStyle>
  );
}
