import React from 'react';
import AboutSection from '../components/AboutSection';
import BlogsSection from '../components/BlogsSection';
import HeroSection from '../components/HeroSection';

export default function Home() {
  return (
    <div>
      <HeroSection />
      <AboutSection />
      <BlogsSection />
    </div>
  );
}
