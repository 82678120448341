import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga4';
import RouteChangeTracker from './components/RouteChangeTracker';
import Footer from './components/Footer';
import NavMenu from './components/NavMenu';
import ScrollToTop from './components/ScrollToTop';
import SmoothScroll from './components/SmoothScroll';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import BlogPost from './pages/BlogPost';
import Blogs from './pages/Blogs';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

export default function App() {
  return (
    <>
      <Router>
        <NavMenu />
        <ScrollToTop />
        <RouteChangeTracker />
        <SmoothScroll>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/blogPost/:slug">
              <BlogPost />
            </Route>
            <Route path="/blogs">
              <Blogs />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
          <Footer />
        </SmoothScroll>
      </Router>
    </>
  );
}
