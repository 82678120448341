import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import Swal from 'sweetalert2';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import { auth, fireStore } from '../utils/FirebaseConfig';
import Pushover from '../utils/PushoverClient';

const CommentFormStyle = styled.div`
  width: 100%;
  form {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--gray-1);
    background-color: var(--deep-dark);
    outline: none;
    border: solid 2px var(--gray-1);
    border-radius: 8px;
    margin-top: 1rem;
    resize: vertical;
  }
  button[type='submit'] {
    background-color: var(--gray-1);
    color: var(--black);
    font-size: 1.5rem;
    display: inline-block;
    outline: none;
    border: none;
    padding: 0.7rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    float: right;
  }
`;

export default function CommentForm({
  slug,
  isAuth,
  setReloadTS,
  setLikesCount,
}) {
  const [isLiked, setIsLiked] = useState(false);
  const [comment, setComment] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const commentsCollectionRef = useRef(collection(fireStore, 'comments'));
  const likesCollectionRef = useRef(collection(fireStore, 'likes'));

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    await executeRecaptcha('action')
      .then((token) => console.log(`recaptcha token: ${token}`))
      .catch(console.error);
  }, [executeRecaptcha]);

  useEffect(() => handleReCaptchaVerify, [handleReCaptchaVerify]);

  useEffect(() => {
    if (!isAuth) return;

    const qry = query(
      likesCollectionRef.current,
      where('slug', '==', slug),
      where('author.id', '==', auth.currentUser.uid)
    );

    const findLike = async () => {
      const data = await getDocs(qry);
      console.log(`initial like: ${data.docs}`);
      setIsLiked(data.docs && data.docs.length > 0);
    };

    findLike();
  }, [slug, isAuth, likesCollectionRef]);

  const insertComment = async () => {
    await addDoc(commentsCollectionRef.current, {
      slug,
      comment,
      author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
      timestamp: Date.now(),
    }).then(() => {
      console.log('comment inserted');
      Pushover.send(
        'New Comment',
        `User: ${auth.currentUser.displayName}\nSlug: ${slug}\nComment: ${comment}`
      )
        .then(console.log)
        .catch(console.error);
    });
  };

  const insertLike = async () => {
    await addDoc(likesCollectionRef.current, {
      slug,
      author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
      timestamp: Date.now(),
    }).then(() => {
      setLikesCount((count) => count + 1);
      console.log('like inserted');
      Pushover.send(
        'New Like',
        `User: ${auth.currentUser.displayName}\nSlug: ${slug}`
      )
        .then(console.log)
        .catch(console.error);
    });
  };

  const getLikes = async () => {
    const qry = query(
      likesCollectionRef.current,
      where('slug', '==', slug),
      where('author.id', '==', auth.currentUser.uid)
    );

    const data = await getDocs(qry);
    if (!data || !data.docs) return [];
    console.log(`loaded likes: ${data.docs}`);
    return data.docs.map((document) => document.id);
  };

  const deleteLike = async () => {
    const likes = await getLikes();
    likes.forEach(async (docId) => {
      const likeDoc = doc(fireStore, 'likes', docId);
      await deleteDoc(likeDoc);
      setLikesCount((count) => count - 1);
      console.log(`like deleted with docId: ${docId}`);
    });
  };

  const postComment = (e) => {
    e.preventDefault();
    handleReCaptchaVerify();
    insertComment();
    setReloadTS(Date.now());
    e.target.reset();
    setComment('');
    Swal.fire({
      icon: 'success',
      title: 'Comment posted successfully.',
    });
  };

  const postLike = () => {
    handleReCaptchaVerify();
    if (isLiked) deleteLike();
    else insertLike();
  };

  return (
    <CommentFormStyle>
      <form id="commentForm" onSubmit={postComment}>
        <div className="comment-form-fields">
          {isAuth && (
            <IconButton
              onClick={() => {
                postLike();
                setIsLiked(!isLiked);
              }}
              aria-label="like"
              color="primary"
            >
              {isLiked ? (
                <FavoriteIcon fontSize="large" />
              ) : (
                <FavoriteBorderIcon fontSize="large" />
              )}
            </IconButton>
          )}
          {isAuth && <button type="submit">Post</button>}
          <textarea
            placeholder="Add comment"
            rows="1"
            required
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </form>
    </CommentFormStyle>
  );
}
