import React from 'react';
import styled from 'styled-components';
import { deleteDoc, doc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import dateFormat from 'dateformat';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { auth, fireStore } from '../utils/FirebaseConfig';

const CommentItemStyles = styled.div`
  #stack {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #stack:hover {
    border: solid 2px var(--gray-1);
    border-radius: 8px;
    background-color: #663f26;
  }
  .delete_icon {
    min-width: 40px;
  }
  .comment {
    flex-grow: 1;
    text-align: left;
    max-width: 800px;
    overflow-wrap: break-word;
  }
  .even_row {
    background-color: #262626;
  }
  .odd_row {
    background-color: #1e1e1e;
  }
  .comment_meta {
    margin-left: auto;
    margin-right: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 768px) {
    .comment__item {
      height: 350px;
    }
  }
`;

export default function CommentItem({
  index,
  comment,
  uname,
  timestamp,
  uid,
  docId,
  isAuth,
  setReloadTS,
}) {
  const deleteComment = async () => {
    const commentDoc = doc(fireStore, 'comments', docId);
    await deleteDoc(commentDoc);
    console.log(`comment deleted with docId: ${docId}`);
    setReloadTS(Date.now());
    Swal.fire({
      icon: 'success',
      title: 'Comment deleted successfully.',
    });
  };

  return (
    <CommentItemStyles>
      <div id="stack" className={index % 2 === 0 ? 'even_row' : 'odd_row'}>
        <div className="delete_icon">
          {isAuth && uid === auth.currentUser.uid && (
            <Tooltip title="Delete comment">
              <IconButton
                onClick={deleteComment}
                aria-label="delete"
                color="primary"
              >
                <DeleteIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="comment">
          <h2>{comment}</h2>
        </div>
        <div className="comment_meta">
          <h3>{uname}</h3>
        </div>
        <div className="comment_meta">
          <h3>{dateFormat(new Date(timestamp))}</h3>
        </div>
      </div>
    </CommentItemStyles>
  );
}
