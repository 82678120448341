import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import ImageGallery from 'react-image-gallery';
import SectionTitle from '../components/SectionTitle';
import SanityClient from '../utils/SanityClient';
import CommentBox from '../components/CommentBox';
import AboutInfoItem from '../components/AboutInfoItem';
import 'react-image-gallery/styles/css/image-gallery.css';

const BlogPostStyle = styled.div`
  padding: 10rem 0;
  .blog__allItems {
    margin-top: 5rem;
  }
  .blogPost__desc {
    font-size: 1.6rem;
    font-family: 'RobotoMono Regular';
    margin-top: 5rem;
  }
`;

export default function BlogPost() {
  const [blogPostData, setBlogPostData] = useState(null);
  const [images, setImages] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    SanityClient.fetch(
      `*[slug.current == "${slug}"]{
      title,
      slug,
      location,
      tags,
      category,
      description,
      mainImage{
        asset->{
          _id,
          url
        },
        alt        
      }
    }
    `
    )
      .then((data) => setBlogPostData(data[0]))
      .catch(console.error);

    fetch(
      `https://8s63u7dc93.execute-api.us-east-1.amazonaws.com/default/listMediaBucket?slug=${slug}`
    )
      .then((response) => response.json())
      .then((keys) => {
        setImages(
          keys.map((key) => ({
            original: `https://d3rk0mocc6mpni.cloudfront.net/${key}`,
          }))
        );
      })
      .catch(console.error);
  }, [slug]);

  if (!blogPostData) return <div>Nothing to show here.</div>;

  if (!Array.isArray(images) || !images.length)
    return <div>Nothing to show here.</div>;

  return (
    <>
      <BlogPostStyle>
        <div className="container">
          <SectionTitle
            heading={blogPostData.title}
            subheading={blogPostData.location}
          />
          <div className="blogPost__desc">
            <BlockContent
              blocks={blogPostData.description}
              projectId="60g5kcqh"
              dataset="production"
            />
          </div>
          <div className="blogPost_tags">
            <AboutInfoItem title="Tags" items={blogPostData.tags} />
          </div>
          <div
            className="blog__allItems"
            onContextMenu={(e) => e.preventDefault()}
          >
            <ImageGallery items={images} />
          </div>
        </div>
      </BlogPostStyle>
      <CommentBox />
    </>
  );
}
