import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import DownloadButton from '../components/DownloadButton';
import AboutInfoItem from '../components/AboutInfoItem';
import SanityClient from '../utils/SanityClient';

const AboutPageStyles = styled.div`
  padding: 20rem 0 10rem 0;

  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .left {
    flex: 3;
  }
  .right {
    flex: 2;
  }
  .about__subheading {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    span {
      background-color: var(--deep-dark);
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  .about__heading {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
  .about__info {
    font-size: 1.6rem;
    font-family: 'RobotoMono Regular';
    margin-top: 4rem;
    margin-bottom: 4rem;
    .para {
      max-width: 100%;
    }
  }
  .right {
    img {
      border: 2px solid var(--gray-1);
    }
  }
  .about__info__items {
    margin-top: 15rem;
  }
  .about__info__item {
    margin-bottom: 10rem;
  }
  .about__info__heading {
    font-size: 3.6rem;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 768px) {
    padding: 10rem 0;
    .top-section {
      flex-direction: column;
      gap: 5rem;
    }
    .about__subheading {
      font-size: 1.8rem;
    }
    .about__heading {
      font-size: 2.8rem;
    }
    .about__info__heading {
      font-size: 3rem;
    }
  }
`;

export default function About() {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "author" && name == "Deepak Kapoor"]{
          title,
          name,
          "imageURL": image.asset->url,
          "resumeURL": resume.asset->url,
          bio
    }
    `
    )
      .then((data) => setAuthor(data[0]))
      .catch(console.error);
  }, []);

  if (!author) return <div>Nothing to show here.</div>;

  return (
    <>
      <AboutPageStyles>
        <div className="container">
          <div className="top-section">
            <div className="left">
              <p className="about__subheading">
                <span>{author.name}</span>
              </p>
              <h2 className="about__heading">{author.title}</h2>
              <div className="about__info">
                <BlockContent
                  blocks={author.bio}
                  projectId="60g5kcqh"
                  dataset="production"
                />
              </div>
              <DownloadButton
                btnText="Download Resume"
                btnLink={author.resumeURL}
              />
            </div>
            <div className="right" onContextMenu={(e) => e.preventDefault()}>
              <img src={author.imageURL} alt={author.name} />
            </div>
          </div>
          <div className="about__info__items">
            <div className="about__info__item">
              <h1 className="about__info__heading">Skills</h1>

              <AboutInfoItem
                title="FrontEnd"
                items={['HTML', 'CSS', 'JavaScript', 'REACT']}
              />
              <AboutInfoItem
                title="BackEnd"
                items={['NodeJS', 'Java', 'SpringBoot']}
              />
              <AboutInfoItem
                title="Scripting"
                items={['Perl', 'Shell', 'Python']}
              />
            </div>
            <div className="about__info__item">
              <h1 className="about__info__heading">Certifications</h1>

              <AboutInfoItem title="Java" items={['Sun Certified Developer']} />
              <AboutInfoItem
                title="Cloud"
                items={['AWS Solutions Architect']}
              />
              <AboutInfoItem
                title="TRM"
                items={["NCFM Derivatives Dealer's Module"]}
              />
            </div>
            <div className="about__info__item">
              <h1 className="about__info__heading">Hobbies</h1>

              <AboutInfoItem title="Sports" items={['Cricket', 'Badminton']} />
              <AboutInfoItem
                title="DIY"
                items={['Home Automation', 'Gardening']}
              />
              <AboutInfoItem
                title="Photography"
                items={['Nature', 'Landscape', 'Portrait']}
              />
            </div>
          </div>
        </div>
      </AboutPageStyles>
    </>
  );
}
