import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function RouteChangeTracker() {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
    console.log(`location = ${JSON.stringify(location)}`);
  }, [location]);

  return <div />;
}
