import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import emailjs, { init } from 'emailjs-com';
import Swal from 'sweetalert2';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

init('user_6jwcDzj9s92kBr4nNHJ37');

const FormStyle = styled.div`
  width: 100%;
  form {
    margin: -0.7vw 0 0;
  }
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
  }
  label {
    font-size: 1.8rem;
  }
  input,
  textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--gray-1);
    background-color: var(--deep-dark);
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1rem;
  }
  textarea {
    min-height: 250px;
    resize: vertical;
  }
  button[type='submit'] {
    background-color: var(--gray-1);
    color: var(--black);
    font-size: 2rem;
    display: inline-block;
    outline: none;
    border: none;
    padding: 1rem 4rem;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export default function ContactForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    await executeRecaptcha('action')
      .then((token) => console.log(`recaptcha token: ${token}`))
      .catch(console.error);
  }, [executeRecaptcha]);

  useEffect(() => handleReCaptchaVerify, [handleReCaptchaVerify]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleReCaptchaVerify();

    emailjs
      .sendForm(
        'service_fakkv8j',
        'template_bx5bvdl',
        '#contactForm',
        'user_6jwcDzj9s92kBr4nNHJ37'
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Message sent successfully.',
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: error,
            title: 'Oops, something went wrong.',
            text: error.text,
          });
        }
      );

    e.target.reset();
  };

  return (
    <>
      <FormStyle>
        <form id="contactForm" onSubmit={handleOnSubmit}>
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="your name"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="your email"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="subject"
              required
            />
          </div>
          <div className="form-group">
            <textarea
              type="text"
              id="message"
              name="message"
              placeholder="message"
              required
            />
          </div>
          <button type="submit">Send</button>
        </form>
      </FormStyle>
    </>
  );
}
