import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdSearch } from 'react-icons/md';
import SectionTitle from '../components/SectionTitle';
import BlogItem from '../components/BlogItem';
import SanityClient from '../utils/SanityClient';

const BlogStyle = styled.div`
  padding: 10rem 0;
  .blogs__allItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 5rem;
    margin-top: 5rem;
  }
  .blogs__searchBar {
    position: relative;
    width: 360px;
    margin-top: 5rem;
  }
  .blogs__searchBar input {
    width: 100%;
    font-size: 2rem;
    padding: 0.8rem;
    color: var(--black);
    border-radius: 6px;
    outline: none;
    border: none;
  }
  .blogs__searchBar .searchIcon {
    position: absolute;
    width: 2rem;

    right: 1rem;
  }
  .blogs__searchBar .searchIcon path {
    color: var(--deep-dark);
  }
  @media only screen and (max-width: 768px) {
    .blogs__searchBar,
    .blogs__searchBar form,
    .blogs__searchBar input {
      width: 100%;
    }
  }
`;

export default function Blogs() {
  const [searchText, setSearchText] = useState('');
  const [blogsData, setBlogsData] = useState(null);
  const [initialBlogsData, setInitialBlogsData] = useState(null);

  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "post"]{
      title,
      slug,
      location,
      tags,
      category,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      }
    } | order(_createdAt desc)
    `
    )
      .then((data) => {
        setInitialBlogsData(data);
        setBlogsData(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (searchText === '') {
      setBlogsData(initialBlogsData);
      return;
    }
    setBlogsData(() =>
      initialBlogsData.filter((item) =>
        item.title.toLowerCase().match(searchText.toLowerCase())
      )
    );
  }, [searchText, initialBlogsData]);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  return (
    <>
      <BlogStyle>
        <div className="container">
          <SectionTitle
            heading="Blogs"
            subheading="an assortment of memories"
          />
          <div className="blogs__searchBar">
            <form>
              <input
                type="text"
                value={searchText}
                onChange={handleChange}
                placeholder="Blog Title"
              />
              <MdSearch className="searchIcon" />
            </form>
          </div>
          <div className="blogs__allItems">
            {blogsData &&
              blogsData.map((item) => (
                <BlogItem
                  key={item.slug.current}
                  slug={item.slug.current}
                  title={item.title}
                  location={item.location}
                  img={item.mainImage.asset.url}
                />
              ))}
          </div>
        </div>
      </BlogStyle>
    </>
  );
}
