import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import BlogItem from './BlogItem';
import SectionTitle from './SectionTitle';
import 'swiper/swiper-bundle.min.css';
import SanityClient from '../utils/SanityClient';

// install Swiper modules
SwiperCore.use([Navigation]);

const BlogSectionStyle = styled.div`
  padding: 10rem 0;
  .swiper-container {
    padding-top: 8rem;
    max-width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    height: 50px;
    width: 50px;
    background: var(--deep-dark);
    z-index: 10;
    right: 60px;
    left: auto;
    top: 0;
    transform: translateY(50%);
    color: var(--gray-1);
    border-radius: 8px;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 2rem;
  }
`;

export default function BlogsSection() {
  const [initialBlogsData, setInitialBlogsData] = useState(null);

  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "post"]{
      title,
      slug,
      location,
      tags,
      category,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      }
    } | order(_createdAt desc)[0..5]
    `
    )
      .then((data) => setInitialBlogsData(data))
      .catch(console.error);
  }, []);

  return (
    <BlogSectionStyle>
      <div className="container">
        <SectionTitle subheading="recent adventures" heading="Blogs" />
        <div className="blogs__allItems">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            navigation
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              // when window width is >= 1200px
              1200: {
                slidesPerView: 3,
              },
            }}
          >
            {initialBlogsData &&
              initialBlogsData.map((blog, index) => {
                if (index > 5) return;
                return (
                  <SwiperSlide key={blog.slug.current}>
                    <BlogItem
                      slug={blog.slug.current}
                      title={blog.title}
                      img={blog.mainImage.asset.url}
                      location={blog.location}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </BlogSectionStyle>
  );
}
